<template>
  <div>
    <span style="cursor: pointer" v-if="!isEditing" @click.stop="startEdit">
      {{ curname }}
    </span>
    <div v-if="isEditing">
      <b-input v-model="name"></b-input>
      <b-button icon-left="floppy" size="is-small" type="is-success" @click="saveName"></b-button>
      <b-button icon-left="close" size="is-small" type="is-danger" @click="stopEdit"></b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'armingscheme_namechange_component',
  props: {
    curname: String,
    readOnly: Boolean,
    reset: Boolean
  },
  data: () => ({
    name: '',
    isEditing: false,
  }),
  beforeMount() {
    this.name = this.curname;
  },
  methods: {
    startEdit() {
      if(this.readOnly) return;
      this.name = this.curname;
      this.isEditing = true;
    },
    saveName() {
      this.isEditing = false;
      if (this.name === this.curname) return;
      this.$emit('saveSchemeName', this.name);
    },
    stopEdit() {
      this.isEditing = false;
      this.name = this.curname;
    },
  },
  watch: {
    reset: function(newVal) {
      if(newVal && this.isEditing) this.stopEdit();
    }
  }
};
</script>

<style lang="scss" scoped>
button {
  margin-right: 5px;
  margin-top: 5px;
  float: right;
}
</style>