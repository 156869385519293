<template>
    <div>
        <b-field>
            <b-field grouped position="is-left">
                <b-field>
                    <b-button icon-left="plus-circle" @click="formModal(null)">Add Exception</b-button>
                </b-field>

                <b-field>
                    <b-button outlined icon-left="chevron-left" type="is-primary"/>
                </b-field>
                <b-field>
                    <b-button outlined icon-left="chevron-right" type="is-primary"/>
                </b-field>
            </b-field>
        </b-field>


    </div>


</template>

<script>
    import ExceptionFormComponent from "./ExceptionFormComponent";
    import moment from 'moment'


    export default {
        name: "AlarmSchemeCalendarComponent",
        components: {  },
        props: ['partitions', 'alarm_id'],
        data() {
            return {
                useCreationPopup: false,
                useDetailPopup: false,
            }

        },

        mounted() {
            this.fetchData()
        },

        methods: {

            onClickSchedule(e) {
                this.formModal(e.schedule.id)
            },

            fetchData() {


                let payload = {
                    alarm_id: this.alarm_id,
                }
                this.$store.dispatch('alarm/fetchExceptions', payload)
                    .then(response => {
                        this.scheduleList = response.data.data.map(item => {
                            return {
                                id: item.recurring_id, recurring_id: item.recurring_id,
                                title: item.description, start:  moment(item.disarm_timestamp).toISOString(),
                                    end:  moment(item.arm_timestamp).toISOString(), calendarId: 1, category: 'time'
                            };
                        });
                    });

            },

            formModal(id) {
                let thisIns = this;
                this.$buefy.modal.open({
                    parent: this,
                    component: ExceptionFormComponent,
                    props: {
                        partitions: this.partitions,
                        id: id,
                        alarm_id: this.alarm_id

                    },
                    hasModalCard: true,
                    customClass: 'custom-class custom-class-2',
                    trapFocus: true,
                    ariaRole: 'dialog',
                    width: 600,
                    events: {
                        refresh () {
                            thisIns.fetchData();
                        }
                    }
                })
            }

        }


    }
</script>

<style scoped>

</style>