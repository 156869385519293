<template>
  <div>
<!--    <div class=" level is-mobile mb-2 pl-1">-->
<!--      <div class=" level-left">-->
<!--        <p class="level-item has-text-left mb-0 py-0">-->
<!--          <b-icon size="is-small" class="mr-2" icon="eye"></b-icon>-->
<!--          <span style="margin-right:  0.7rem;" class="title is-6">Upcoming exceptions</span>-->
<!--        </p>-->
<!--      </div>-->
<!--      <div class="level-right">-->
<!--        <p class="level-item py-0">-->
<!--          <small class="is-5">This week</small>-->
<!--        </p>-->
<!--      </div>-->
<!--    </div>-->


    <card-component title="Upcoming exceptions" icon="calendar-plus" class="has-table" :has-button-slot="true">
      <b-button v-permission:any="`${project_id}.add exceptions|manage all exceptions`" icon-left="plus-circle"  size="is-small" slot="button" @click="formModal(null)">Add exception</b-button>

      <div class="card-content px-4 py-4">
        <b-message  type="is-info" has-icon aria-close-label="Close message">
          You can use exceptions to <b>override the schema on a specific day.</b> For example, if work starts early (5:00 instead of 6:00),
          you can add an exception from 5:00 until the alarm needs to armed again (e.g. 18:00).
        </b-message>
      </div>




      <b-table
          :data="upcomingExceptions"
          ref="table"
          detailed
          :loading="isLoading"
          hoverable
          custom-detail-row
        >

        <b-table-column
            field="title"
            label="Description"
            v-slot="props"
        >
          <template>
            <b-icon size="is-small" icon="calendar-edit"></b-icon>
            <b class="pl-2">{{ props.row[0].description }}</b>
          </template>
        </b-table-column>

        <b-table-column
            field="title"
            label="Active on"
            v-slot="props"
        >
          <template v-if="props.row[0].partitions.length > 0">
              <div class="control" v-for="partition in props.row[0].partitions">
                <b-taglist attached>
                  <b-tag type="is-grey is-light"> {{ partition.package.name }}</b-tag>
                  <b-tag type="is-info is-light"> {{ partition.name }}</b-tag>
                </b-taglist>
              </div>
          </template>
          <template v-else>
            <small>No partition linked</small>
          </template>
        </b-table-column>

        <b-table-column
            field="recurring"
            label="Recurring"
            v-slot="props"
        >
          <template>

            <b-tag v-if="props.row[0].recurring" size="is-small">
              <b-icon size="is-small" icon="repeat"></b-icon>
              <span>
                {{ props.row[0].recurring }}
              </span>

              <span>
              ,  {{ props.row[0].recurring_amount }} times
              </span>
            </b-tag>

            <b-tag v-else>
              <b-icon size="is-small" icon="repeat-off"></b-icon>
              <span> not recurring</span>
            </b-tag>

          </template>
        </b-table-column>

        <b-table-column
            field="time"
            label="Time"
            centered
            v-slot="props"
        >
          <b-icon type="is-success" size="is-small" icon="lock-open"></b-icon>
          <span><small>{{ props.row[0].disarm_timestamp | moment('ddd DD MMM, HH:mm') }}</small></span><br>
          <b-icon type="is-danger" size="is-small" icon="lock"></b-icon>
          <span><small>{{ props.row[0].arm_timestamp | moment('ddd DD MMM, HH:mm') }}</small></span>

        </b-table-column>

        <b-table-column custom-key="actions" class="is-actions-cell" v-slot="props">
          <div v-permission:any="`${id}.delete exceptions|manage all exceptions`" class="buttons is-right">
<!--            <b-button icon-left="pencil" size="is-small" type="is-success" @click="formModal(props.row[0].id)"></b-button>-->
            <b-button icon-left="delete" size="is-small" type="is-danger" @click="confirmDelete(props.row[0])"></b-button>
          </div>
        </b-table-column>

        <template slot="detail" slot-scope="props">
          <tr v-for="item in props.row.slice(1)" :key="item.id">
            <td></td>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td></td>
            <td></td>


            <td class="has-text-centered">
              <b-icon type="is-success" size="is-small" icon="lock-open"></b-icon>
              <span><small>{{ item.disarm_timestamp | moment('ddd DD MMM, HH:mm') }}</small></span><br>
              <b-icon type="is-danger" size="is-small" icon="lock"></b-icon>
              <span><small>{{ item.arm_timestamp| moment('ddd DD MMM, HH:mm') }}</small></span>
            </td>
            <td  class="is-actions-cell"></td>


          </tr>
        </template>

        <template slot="empty">
          <br>
          <p class="has-text-centered">
            <b-icon icon="cactus" size="is-large" />
          </p>
          <p class="has-text-centered">No upcoming exceptions found for this project</p>
          <br>
        </template>

      </b-table>





    </card-component>

    <card-component title="Past 5 exceptions" icon="calendar-remove" class="has-table" :has-button-slot="false">
      <b-table
          :data="pastExceptions"
          ref="table"
          detailed
          :loading="isLoading"
          hoverable
          custom-detail-row
      >

        <b-table-column
            field="title"
            label="Description"
            v-slot="props"
        >
          <template>
            <b-icon size="is-small" icon="calendar-edit"></b-icon>
            <b class="pl-2">{{ props.row[0].description }}</b>
          </template>
        </b-table-column>

        <b-table-column
            field="title"
            label="Was active on"
            v-slot="props"
        >
          <template v-if="props.row[0].partitions.length > 0">
            <div class="control" v-for="partition in props.row[0].partitions">
              <b-taglist attached>
                <b-tag type="is-grey is-light"> {{ partition.package.name }}</b-tag>
                <b-tag type="is-info is-light"> {{ partition.name }}</b-tag>
              </b-taglist>
            </div>
          </template>
          <template v-else>
            <small>No partition linked</small>
          </template>
        </b-table-column>

        <b-table-column
            field="recurring"
            label="Recurring"
            v-slot="props"
        >
          <template>

            <b-tag v-if="props.row[0].recurring" size="is-small">
              <b-icon size="is-small" icon="repeat"></b-icon>
              <span>
                {{ props.row[0].recurring }}
              </span>

              <span>
              ,  {{ props.row[0].recurring_amount }} times
              </span>
            </b-tag>

            <b-tag v-else>
              <b-icon size="is-small" icon="repeat-off"></b-icon>
              <span> not recurring</span>
            </b-tag>

          </template>
        </b-table-column>

        <b-table-column
            field="time"
            label="Time"
            centered
            v-slot="props"
        >
          <b-icon type="is-success" size="is-small" icon="lock-open"></b-icon>
          <span><small>{{ props.row[0].disarm_timestamp | moment('ddd DD MMM, HH:mm') }}</small></span><br>
          <b-icon type="is-danger" size="is-small" icon="lock"></b-icon>
          <span><small>{{ props.row[0].arm_timestamp | moment('ddd DD MMM, HH:mm') }}</small></span>

        </b-table-column>

        <template slot="detail" slot-scope="props">
          <tr v-for="item in props.row.slice(1)" :key="item.id">
            <td></td>
            <td>&nbsp;&nbsp;&nbsp;&nbsp;</td>
            <td></td>
            <td></td>


            <td class="has-text-centered">
              <b-icon type="is-success" size="is-small" icon="lock-open"></b-icon>
              <span><small>{{ item.disarm_timestamp | moment('ddd DD MMM, HH:mm') }}</small></span><br>
              <b-icon type="is-danger" size="is-small" icon="lock"></b-icon>
              <span><small>{{ item.arm_timestamp| moment('ddd DD MMM, HH:mm') }}</small></span>
            </td>
            <td  class="is-actions-cell"></td>


          </tr>
        </template>

        <template slot="empty">
          <br>
          <p class="has-text-centered">
            <b-icon icon="cactus" size="is-large" />
          </p>
          <p class="has-text-centered">No past exceptions found for this project</p>
          <br>
        </template>

      </b-table>





    </card-component>

  </div>


</template>

<script>
import ExceptionFormComponent from "./ExceptionFormComponent";
import moment from 'moment'
import CardComponent from "@/components/CardComponent";
import ExceptionUpdateStatusComponent from "@/components/package/schemes/ExceptionUpdateStatusComponent";
import {groupBy, values} from "lodash";


export default {
  name: "ExceptionOverviewComponent",
  components: {CardComponent  },
  props: ['partitions', 'project_id'],
  data() {
    return {
      upcomingExceptions: [],
      pastExceptions: [],
      isLoading: false,

    }
  },

  mounted() {
    this.fetchData()
  },

  methods: {

    fetchData() {
      this.isLoading = true
      let payload = {
        project_id: this.project_id,
      }
      this.$store.dispatch('alarm/fetchExceptions', payload)
          .then(response => {
            this.isLoading = false
            this.upcomingExceptions = values(groupBy(response.data.upcoming, 'recurring_id'))
            this.pastExceptions = values(groupBy(response.data.past, 'recurring_id'))

          }).catch(error => {
            this.isLoading = false
            this.$buefy.toast.open({
              message: 'Schemes could not be loaded',
              type: 'is-danger'
            })
          })

    },

    formModal(id) {
      let thisIns = this;
      this.$buefy.modal.open({
        parent: this,
        component: ExceptionFormComponent,
        props: {
          partitions: this.partitions,
          id: id,
          project_id: this.project_id

        },
        hasModalCard: true,
        trapFocus: true,
        ariaRole: 'dialog',
        width: 600,
        events: {
          refresh () {
            thisIns.fetchData();
          }
        }
      })
    },
    confirmDelete(exception) {
      this.$buefy.dialog.confirm({
        title: 'Deleting exception',
        message: 'Are you sure you want to <b>delete</b> ' + exception.title + '? This action will remove the planned exceptions from the alarm systems and cannot be undone. <b>Recurring exceptions will be deleted as well.</b>',
        confirmText: 'Delete exception',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteException(exception)
      })
    },
    deleteException(exception) {
      this.isLoading = true;
      this.$store.dispatch('alarm/deleteException', {
        "project_id": this.project_id,
        "exception_id": exception.id
      })
          .then(response => {
            this.fetchData()
            this.isLoading = false;
            this.updateResultModal(response.data.partition_updates, response.data.currently_running)
          }).catch(err => {
            this.isLoading = false;
            this.$buefy.toast.open({
              message: err,
              type: 'is-danger'
            })
      })

    },

    updateResultModal(part_upd, currently_running) {
      this.$buefy.modal.open({
        parent: this,
        component: ExceptionUpdateStatusComponent,
        props: {
          partition_updates: part_upd,
          currently_running: currently_running
        },
        hasModalCard: true,
        trapFocus: true,
      })

    },


    groupBy(xs, key) {
      return xs.reduce(function(rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    }


  }


}
</script>

<style scoped>

</style>
