<template>

  <section class="section is-main-section">
    <title-bar :title-stack="titleStack"/>
    <div class="tile is-ancestor is-vertical">
      <div class="tile is-parent">
        <card-component class="tile is-child has-table" icon="calendar-clock" title="Weekly Schemes"
                        :has-button-slot="true">
          <b-button v-permission="'manage all schemas'" :loading="syncLoading" icon-left="refresh" size="is-small"
                    slot="button" @click="confirmSync">Fetch schemes from panel
          </b-button>
          <b-button v-permission:any="`${id}.add schemas|manage all schemas`" icon-left="plus-circle" size="is-small" slot="button"
                    @click="createModal">Add scheme
          </b-button>

          <b-table
              :data="schemes"
              ref="table"
              detailed
              detail-key="id"
              :loading="loading"
          >
            <b-table-column field="scheme.name" label="Schema" v-slot="props">

                <armingscheme_namechange_component
                  :curname="props.row.name"
                  :read-only="!($gates.hasAnyPermission(`${id}.update schemas|manage all schemas`) || $gates.isSuperUser())"
                  :reset="loading"
                  @saveSchemeName="onSaveSchemeName(props.row.id, props.index, $event)"
              ></armingscheme_namechange_component>

            </b-table-column>
            <b-table-column field="scheme.partition" label="Active on partitions" v-slot="props">
              <b-field grouped group-multiline>
                <div class="control" v-for="partition in props.row.partitions">
                  <b-taglist attached>
                    <b-tag type="is-grey is-light"> {{ partition.package.name }}</b-tag>
                    <b-tag type="is-info is-light"> {{ partition.name }}</b-tag>
                  </b-taglist>
                </div>
                <div v-if="props.row.partitions.length <= 0">
                  <small>Not active on any partition</small>
                </div>
              </b-field>
            </b-table-column>

            <template slot="detail" slot-scope="props">
              <form class="content">
                <b-field label="Activate for partitions" horizontal>
                  <template slot="label">
                    Activate on partitions
                  </template>

                  <b-taginput v-if="!loading"
                              :data="availablePartitions(modifiable_schemes[props.index].id)"
                              v-model="modifiable_schemes[props.index].partitions"
                              ref="taginput"
                              :disabled="!($gates.hasAnyPermission(`${id}.update schemas|manage all schemas`) || $gates.isSuperUser())"
                              autocomplete
                              :allow-new="false"
                              :open-on-focus="true"
                              group-field="package.name"
                              field="name"
                              type="is-info is-light"
                              icon="circle"
                              placeholder="Add a partition"
                              append-to-body
                              @typing="getFilteredTags(text, modifiable_schemes[props.index].id)">

                    <template slot="selected" slot-scope="props">
                      <b-taglist attached v-for="(tag, index) in props.tags" :key="index">
                        <b-tag type="is-grey is-light"> {{ tag.package.name }}</b-tag>
                        <b-tag type="is-info is-light"
                               :tabstop="false"
                               closable
                               @close="$refs.taginput.removeTag(index, $event)"
                        > {{ tag.name }}
                        </b-tag>
                      </b-taglist>
                    </template>
                  </b-taginput>
                </b-field>

                <div v-if="modifiable_schemes[props.index]">
                  <b-field horizontal v-for="(day, dayindex) in modifiable_schemes[props.index].hours" :key="dayindex">
                    <template slot="label">
                      <span class=" has-text-weight-regular">{{ day.day }} </span>
                      <span v-if="day.times[1] - day.times[0] === 0" class=" has-text-weight-light">continue</span>
                    </template>
                    <div class="block">
                      <b-slider greyed type="is-success"
                                :disabled="!($gates.hasAnyPermission(`${id}.update schemas|manage all schemas`) || $gates.isSuperUser())"
                                v-model="day.times"
                                v-bind:class="{'greyed': day.times[1] - day.times[0] === 0 || day.times[1] - day.times[0] === 1024}"
                                :key="getTimeFromMins(day.times)"
                                :value="getTimeFromMins(day.times)"
                                :custom-formatter="val => getTimeFromMins(val)"
                                :min="0"
                                :max="1440"
                                :step="15"
                      >

                        <template v-for="hour in initHours">
                          <b-slider-tick :value="hour.id" :key="hour.id">{{ hour.time }}</b-slider-tick>
                        </template>

                      </b-slider>
                    </div>
                  </b-field>
                </div>
                <br>


                <div class="level">
                  <div class="level-left">
                    <b-field>
                      <b-button v-permission:any="`${id}.delete schemas|manage all schemas`" icon-left="delete" type="is-danger"
                                outlined @click="confirmDelete(modifiable_schemes[props.index])"></b-button>
                    </b-field>
                  </div>

                  <div class="level-right">
                    <b-field grouped>
                      <b-field>
                        <b-button type="is-grey">Cancel</b-button>
                      </b-field>
                      <b-field>
                        <b-button v-permission:any="`${id}.update schemas|manage all schemas`" type="is-primary"
                                  @click="confirmUpdate(props.index)">Apply
                        </b-button>
                      </b-field>
                    </b-field>

                  </div>
                </div>

              </form>

            </template>

            <template slot="empty">
              <br>
              <p class="has-text-centered">
                <b-icon icon="cactus" size="is-large"/>
              </p>
              <p class="has-text-centered">No schemes found for this project</p>
              <br>
            </template>

          </b-table>

        </card-component>
      </div>

      <div class="tile is-parent">
        <div class="tile is-child">

          <exception-overview-component :partitions="partitions" :project_id="id"></exception-overview-component>

        </div>
      </div>

    </div>

  </section>

</template>

<script>

import CardComponent from '@/components/CardComponent'
import moment from 'moment';
import AlarmSchemeCalendarComponent from "../components/package/schemes/AlarmSchemeCalendarComponent";
import alarm from "../store/modules/alarm.store"
import SchemeUpdateStatusComponent from "../components/package/schemes/SchemeUpdateStatusComponent";
import ExceptionOverviewComponent from "@/components/package/schemes/ExceptionOverviewComponent";
import TitleBar from "@/components/TitleBar";
import SchemeSyncStatusComponent from "@/components/package/schemes/SchemeSyncStatusComponent";
import Armingscheme_namechange_component from "@/components/project/ArmingschemeNamechangeComponent";


export default {
  name: 'ProjectAlarmScheme',
  components: {
    Armingscheme_namechange_component,
    AlarmSchemeCalendarComponent, CardComponent, ExceptionOverviewComponent, TitleBar},
  data() {
    return {
      activeTab: 1,
      isReady: false,
      loading: false,
      syncLoading: false,
      numbers: [],
      modifiable_schemes: [],
      partitions: [],
      filteredTags: [],
      weekdays: [
        {
          'day': 'MON',
          'times': []
        },
        {
          'day': 'TUE',
          'times': []
        },
        {
          'day': 'WED',
          'times': []
        },
        {
          'day': 'THU',
          'times': []
        },
        {
          'day': 'FRI',
          'times': []
        },
        {
          'day': 'SAT',
          'times': []
        },
        {
          'day': 'SUN',
          'times': []
        }]

    }
  },
  computed: {
    titleStack() {
      return [
        //this.project_name ? this.project_name : "...",
        'Project',
        'Schemes'
      ]
    },
    id() {
      return parseInt(this.$route.params.project_id)
    },
    initHours() {
      let items = [];
      new Array(24).fill().forEach((acc, index) => {
        items.push({id: index * 60, time: index});
      })
      return items;
    },
    schemes() {
      return this.$store.getters['alarm/schemesForProject'](this.id)
    }

  },
  created() {
    if (!alarm.isRegistered) {
      this.$store.registerModule('alarm', alarm)
      alarm.isRegistered = true
    }
  },

  mounted() {
    this.fetchData(true)
  },

  methods: {
    availablePartitions(scheme_id) {
      return this.$store.getters['alarm/availablePartitions'](scheme_id)
    },

    getFilteredTags(text, scheme_id) {
      this.filteredTags = this.availablePartitions(scheme_id).filter((option) => {
        return option.package.name + '| ' + option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
      })
    },

    fetchData(load = false) {
      this.loading = load
      let payload = {
        project_id: this.id,
        params: "include=partitions"
      }
      this.$store.dispatch('alarm/fetchProjectPartitions', payload)
          .then(response => {
            this.partitions = response.data
          })
      this.$store.dispatch('alarm/fetchSchemes', payload)
          .then(response => {
            this.loading = false;
            this.updateSchemes(response);
          }).catch( resp => {
            this.loading = false;
      })

    },

    createModal() {
      this.$buefy.dialog.prompt({
        message: `Name of the new scheme`,
        inputAttrs: {
          placeholder: 'e.g. Main scheme (max 16 chars)',
          maxlength: 16,
          required: true
        },
        trapFocus: true,
        onConfirm: (value) => this.createScheme(value)
      })
    },

    updateResultModal(part_upd) {
      this.$buefy.modal.open({
        parent: this,
        component: SchemeUpdateStatusComponent,
        props: {
          partition_updates: part_upd,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          update: () => {
            this.$emit('update')
          }
        }
      })

    },

    createScheme(name) {
      this.isReady = false

      this.$store.dispatch('alarm/createScheme', {
        "id": this.id,
        "data": {
          "name": name
        },
      })
          .then(response => {
            this.fetchData();

            this.$buefy.toast.open({
              type: "is-success",
              message: `Scheme created`
            })
          })
    },

    confirmDelete(scheme) {
      this.$buefy.dialog.confirm({
        title: 'Deleting schema',
        message: 'Are you sure you want to <b>delete</b> ' + scheme.name + '? This action will remove the scheme from the alarm system and cannot be undone.',
        confirmText: 'Delete Scheme',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => this.deleteScheme(scheme)
      })
    },
    deleteScheme(scheme) {
      this.$store.dispatch('alarm/deleteScheme', {
        "project_id": this.id,
        "scheme_id": scheme.id
      })
          .then(response => {
            this.$buefy.toast.open(`Scheme deleted`)
          })

    },

    confirmSync(index) {
      this.$buefy.dialog.confirm({
        title: 'Fetch schemas',
        message: 'Are you sure you want to <b>fetch</b> the schemas from the panels?',
        confirmText: 'Update Scheme',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.syncSchemas()
      })
    },
    syncSchemas() {
      this.syncLoading = true
      this.loading = true
      const toast = this.$buefy.toast.open({
        message: 'Syncing schemas.. This can take a while...',
        indefinite: true,
      })
      this.$store.dispatch('alarm/syncSchemes', {project_id: this.id, params: '&sync=1'})
          .then(response => {
            this.syncLoading = false
            this.loading = false
            toast.close()
            this.syncResultModal(response.package_updates)
            this.fetchData()
          })
          .catch(error => {
            this.syncLoading = false
            toast.close()
            this.loading = false
            this.$buefy.toast.open({
              message: 'Schemes could not be synced ' + error,
              type: 'is-danger'
            })
          })
    },
    syncResultModal(package_updates) {
      this.$buefy.modal.open({
        parent: this,
        component: SchemeSyncStatusComponent,
        props: {
          package_updates: package_updates,
        },
        hasModalCard: true,
        trapFocus: true,
      })

    },
    onSaveSchemeName(scheme_id, index, newname) {
      this.$store.dispatch('alarm/updateSchemeNameOnly', {
        scheme_id: scheme_id,
        project_id: this.id,
        name: newname,
      }).then(response => {
        this.schemes[index].name = newname;
        this.$buefy.toast.open({
          message: 'Scheme name updated',
          type: 'is-success'
        })
      })

    },

    confirmUpdate(index) {
      this.$buefy.dialog.confirm({
        title: 'Updating schema',
        message: 'Are you sure you want to <b>update</b> this schema? This action will be logged and new schemes will be sent to the alarm system.',
        confirmText: 'Update Scheme',
        type: 'is-warning',
        hasIcon: true,
        onConfirm: () => this.updateScheme(index)
      })
    },
    updateScheme(index) {
      this.loading = true
      const pluck = key => array => Array.from(new Set(array.map(obj => obj[key])));
      const getId = pluck('id');

      // Same start/end time --> set to 00:00
      this.modifiable_schemes[index].hours.forEach(day => {
        if(day.times[0] === day.times[1]) {
          day.times[0] = 0
          day.times[1] = 0
        }
      })

      const newscheme = {
        "MO_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[0].times[1]),
        "MO_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[0].times[0]),
        "TU_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[1].times[1]),
        "TU_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[1].times[0]),
        "WE_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[2].times[1]),
        "WE_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[2].times[0]),
        "TH_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[3].times[1]),
        "TH_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[3].times[0]),
        "FR_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[4].times[1]),
        "FR_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[4].times[0]),
        "SA_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[5].times[1]),
        "SA_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[5].times[0]),
        "SU_arm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[6].times[1]),
        "SU_disarm_hour_1": this.getTimeFromMins(this.modifiable_schemes[index].hours[6].times[0]),
        "name": this.modifiable_schemes[index].name,
        "partition_ids": this.modifiable_schemes[index].partitions.map(p => p.id).filter((v, i, a) => a.indexOf(v) === i) // select distinct values
      }

      const toast = this.$buefy.toast.open({
        message: 'Pushing schemas.. This can take a while...',
        indefinite: true,
      })

      this.$store.dispatch('alarm/updateScheme', {
        "project_id": this.id,
        "scheme_id": this.modifiable_schemes[index].id,
        "data": newscheme
      })
          .then(response => {
            this.loading = false
            toast.close()
            this.updateResultModal(response.data.partition_updates)
            for (let i = 0; i < response.data.partition_updates.length; ++i) {
              if (response.data.partition_updates[i].status !== 'ok') {
                this.modifiable_schemes[index].partitions[i].active = false
              }
            }

            this.fetchData()

          }).catch(error => {
        this.loading = false
        toast.close()
        this.$buefy.toast.open({
          message: 'Scheme could not be updated: ' + error,
          type: 'is-danger'
        })
        this.fetchData()


      })

    },

    parseTimes(day_schedule) {

      let arm_hour = moment.duration(day_schedule.arm_hour).asMinutes()
      let disarm_hour = moment.duration(day_schedule.disarm_hour).asMinutes()

      if(disarm_hour > arm_hour && arm_hour === 0) {
        arm_hour = 1440
      }

      return [ disarm_hour, arm_hour ]

    },
    updateSchemes(response) {

      this.modifiable_schemes = []
      for (let index = 0; index < response.length; ++index) {
        let hours = JSON.parse(JSON.stringify(this.weekdays))
        hours[0].times = this.parseTimes(response[index].schedule_1.MO);
        hours[1].times = this.parseTimes(response[index].schedule_1.TU);
        hours[2].times = this.parseTimes(response[index].schedule_1.WE);
        hours[3].times = this.parseTimes(response[index].schedule_1.TH);
        hours[4].times = this.parseTimes(response[index].schedule_1.FR);
        hours[5].times = this.parseTimes(response[index].schedule_1.SA);
        hours[6].times = this.parseTimes(response[index].schedule_1.SU);
        let newscheme = {
          'name': response[index].name,
          'id': response[index].id,
          'hours': hours,
          'partitions': response[index].partitions
        }

        this.modifiable_schemes.push(newscheme)
      }
      this.isReady = true
    },
    getTimeFromMinsArray(array) {
      return [this.getTimeFromMins(array[0]), this.getTimeFromMins(array[1])]
    },
    getTimeFromMins(mins) {

      if (mins === 1440) {
        return "24:00"
      }
      // do not include the first validation check if you want, for example,
      // getTimeFromMins(1530) to equal getTimeFromMins(90) (i.e. mins rollover)
      // if (mins >= 24 * 60 || mins < 0) {
      //     throw new RangeError("Valid input should be greater than or equal to 0 and less than 1440.");
      // }
      var h = mins / 60 | 0,
          m = mins % 60 | 0;
      return moment.utc().hours(h).minutes(m).format("HH:mm");
    }


  }
}
</script>

<style lang="scss">

@import "src/scss/theme-default";

.b-slider .b-slider-track {
  background: $danger;
}

.greyed .b-slider-track {
  background: $grey;
}



</style>
