<template>
    <card-component :title="this.creating ? 'Add Exception': 'Edit Exception'"
                    :icon="this.creating ? 'plus-circle' : 'pencil'"
                    :is-scrollable="true">
        <div class="content">
            <b-notification type="is-info">
                Exceptions will take precedence on existing arming schemes. An exception is defined by a disarm- and arming
                timestamp and can be repeated daily, weekly or monthly.
            </b-notification>

            <form @submit.prevent="submit">

              <b-field label="Activate on partitions">

                <b-taginput
                    :data="partitions"
                    v-model="exception.partitions"
                    ref="taginput"
                    autocomplete
                    :allow-new="false"
                    :open-on-focus="true"
                    group-field="package.name"
                    field="name"
                    type="is-info is-light"
                    icon="circle"
                    placeholder="Add a partition"
                    append-to-body
                    @typing="getFilteredTags">

                  <template slot="selected" slot-scope="props">
                    <b-taglist attached v-for="(tag, index) in props.tags" :key="index">
                      <b-tag type="is-grey is-light"> {{ tag.package.name }}</b-tag>
                      <b-tag type="is-info is-light"
                             :tabstop="false"
                             closable
                             @close="$refs.taginput.removeTag(index, $event)"
                      > {{ tag.name }}</b-tag>
                    </b-taglist>
                  </template>
                </b-taginput>
              </b-field>

                <b-field label="Disarm at" expanded>
                    <b-datetimepicker
                            v-model="exception.disarm_timestamp"
                            rounded
                            placeholder="Disarm at..."
                            icon="calendar-today"
                            :min-datetime="new Date(new Date().valueOf() + 60000)"
                            :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                            horizontal-time-picker>
                    </b-datetimepicker>
                </b-field>
                <b-field label="Arm at" expanded>
                    <b-datetimepicker
                            v-model="exception.arm_timestamp"
                            rounded
                            placeholder="Arm at..."
                            icon="calendar-today"
                            :min-datetime="new Date(new Date().valueOf() + 60000)"
                            :timepicker="{ enableSeconds: false, hourFormat: '24' }"
                            horizontal-time-picker>
                    </b-datetimepicker>
                </b-field>

                <b-field label="Repeat">
                    <b-field>

                        <b-select v-model="exception.recurring" expanded>
                            <option :value="null">Never</option>
                            <option value="daily">Daily</option>
                            <option value="weekly">Weekly</option>
                            <option value="monthly">Monthly</option>
                        </b-select>
                        <b-numberinput v-if="exception.recurring" v-model="exception.recurring_amount" v-bind:disabled="this.exception.recurring === null" controls-position="compact"/>
                        <p v-if="exception.recurring" class="control">
                            <span class="button is-static">times</span>
                        </p>
                    </b-field>
                </b-field>

                <b-field label="Description">
                    <b-input required v-model="exception.description" maxlength="100" type="textarea" ></b-input>
                </b-field>

              <p class="text is-size-7">
                If a schema was active on the moment an exception is added, the schema will be reactivated when the exception finishes.
              </p>
              <hr>

                <b-field horizontal >
                    <b-field grouped position="is-right">
                        <div class="control" v-if="!creating">
                            <b-button type="is-danger is-outlined" @click="removeModal">Delete</b-button>
                        </div>
                        <div class="control">
                            <b-button :loading="isLoading" native-type="submit" type="is-primary">{{ this.creating ? "Create" : "Update" }}</b-button>
                        </div>
                    </b-field>
                </b-field>

            </form>
        </div>
    </card-component>
</template>

<script>
    import alarms from "@/store/modules/alarm.store"
    import CardComponent from "@/components/CardComponent";
    import ExceptionUpdateStatusComponent from "@/components/package/schemes/ExceptionUpdateStatusComponent";

    export default {
        name: "ExceptionFormComponent",
        components: { CardComponent },
        props: {
            partitions: {
                default: null
            },
            id: {
                default: null
            },
            project_id: {
                default: null
            }
        },
        data() {
            return {
                exception: {},
                isLoading: false,
            }
        },

        computed: {
            creating () {
                return this.id === null
            },


        },
        created() {
            if(!alarms.isRegistered) {
                this.$store.registerModule('alarms', alarms)
                alarms.isRegistered = true
            }
        },

        mounted() {
            if (this.id === null) {
                this.exception = {
                    partition_ids: [],
                    disarm_timestamp: new Date(new Date().valueOf() + 180000),
                    arm_timestamp: new Date(new Date().valueOf() + 360000),
                    recurring: null,
                    recurring_amount: 2,
                    description: "",

                }
            }
            else {
                // Fetch alarm data
                this.$store.dispatch('alarm/fetchExceptionDetails', { project_id: this.project_id, id: this.id })
                    .then(response => {
                        let result = JSON.parse(JSON.stringify(response.data.data))
                        this.exception = result
                        this.exception.arm_timestamp = new Date(result.arm_timestamp)
                        this.exception.disarm_timestamp = new Date(result.disarm_timestamp);

                    })
            }
        },

        methods: {

          getFilteredTags(text) {
            this.filteredTags = this.availablePartitions.filter((option) => {
              return option.package.name + '| ' + option.name
                  .toString()
                  .toLowerCase()
                  .indexOf(text.toLowerCase()) >= 0
            })
          },

            submit() {
                // Add new exception
                this.isLoading = true;
                if (this.creating) {
                    const pluck = key => array => Array.from(new Set(array.map(obj => obj[key])));
                    const getId = pluck('id');
                    if( ! this.exception.partitions) {
                      this.isLoading = false;
                      this.$buefy.toast.open({
                        message: 'Select at least one partition!',
                        type: 'is-warning'
                      })
                      return
                    }
                    this.exception.partition_ids =  this.exception.partitions.map(p => p.id)
                    this.$store.dispatch('alarm/createException', {data: this.exception, project_id: this.project_id})
                        .then(response => {

                          this.$emit("refresh")
                          this.isLoading = false;
                          this.updateResultModal(response.data.partition_updates)
                          this.$parent.close()

                        }).catch(err => {
                      this.isLoading = false;

                      this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                    })
                }
                // Update existing exception
                else {
                    this.$store.dispatch('alarm/updateException', { project_id: this.project_id, id: this.id, data: this.exception })
                        .then(response => {
                          this.isLoading = false;
                          this.$parent.close()
                            this.$buefy.toast.open({
                                message: 'Exception updated',
                                type: 'is-success'
                            })

                        }).catch(err => {
                      this.isLoading = false;
                      this.$buefy.toast.open({
                            message: err,
                            type: 'is-danger'
                        })
                    })
                }

            },

            deleteModal(id) {
                this.$buefy.dialog.confirm({
                    title: 'Removing Exception',
                    message: 'Are you sure you want to <b>remove</b> this exception? This action cannot be undone.',
                    confirmText: 'Remove',
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.$store.dispatch('alarm/deleteException', {project_id: this.project_id, id: this.id})
                            .then(response => {
                                this.$emit("refresh")
                                this.$buefy.toast.open('Exception removed')
                            })
                    }
                })
            },

          updateResultModal(part_upd) {
            this.$buefy.modal.open({
              parent: this,
              component: ExceptionUpdateStatusComponent,
              props: {
                partition_updates: part_upd,
              },
              hasModalCard: true,
              trapFocus: true,
              events: {
                update: () =>  {
                  this.$emit('refresh')
                }
              }
            })

          },
        }

    }
</script>

<style scoped lang="scss">


</style>