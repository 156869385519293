<template>
  <card-component title="Edit Output"
                  icon="pencil"
                  :is-scrollable="true">
    <div class="content">

      <form @submit.prevent="submit">

        <b-field horizontal label="Internal Name"
                 message="Name can only be changed through Risco CS and will be applied when synced." expanded>
          <b-input disabled v-model="output.internal_name"></b-input>
        </b-field>

        <b-field horizontal label="Name" message="Visible for clients" expanded>
          <b-input v-model="output.name"></b-input>
        </b-field>

        <b-field horizontal label="In Use"
                 message="When in use, the output will be visible in Package details an can be switched. Make sure that output is set to 'Code' in risco CS.">
          <b-checkbox true-value="1" false-value="0" v-model="output.used">
          </b-checkbox>
        </b-field>

        <b-field horizontal label="Invert output"
                 message="Change circuit funcitoning from NO to NC">
          <b-checkbox @input="modechange" :true-value="1" :false-value="0" v-model="output.invert">
          </b-checkbox>
        </b-field>

        <b-field horizontal label="Icon">
          <b-dropdown v-model="output.icon" aria-role="list">
            <button class="button is-outlined" type="button" slot="trigger">
              <b-icon :icon="output.icon"></b-icon>
              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item v-for="item in iconList" :value="item.icon" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" :icon="item.icon"></b-icon>
                <div class="media-content">
                  <b>{{ item.name }}</b>
                </div>
              </div>
            </b-dropdown-item>


          </b-dropdown>
        </b-field>

        <hr>

        <b-field horizontal label="Output Mode" :message="currentOutputMode.description">
          <b-dropdown @input="modechange" v-model="currentOutputMode" aria-role="list">
            <button class="button is-outlined" type="button" slot="trigger">
              <b-icon :icon="currentOutputMode.icon"></b-icon>
              <span>{{ currentOutputMode.name }}</span>
              <b-icon icon="menu-down"></b-icon>
            </button>

            <b-dropdown-item v-for="item in modeList" :value="item" aria-role="listitem">
              <div class="media">
                <b-icon class="media-left" :icon="item.icon"></b-icon>
                <div class="media-content">
                  <p class="mb-0"><b>{{ item.name }}</b></p>
                  <small>{{ item.description }}</small>
                </div>
              </div>
            </b-dropdown-item>


          </b-dropdown>
        </b-field>

        <b-field horizontal v-if="currentOutputMode.value === 1" label="Arm partition">
          <b-select @input="modechange" v-model="output.mode_data.arm_partition_id" expanded icon="circle-medium">
            <option v-for="_partition in partitions" :value="_partition.id">{{ _partition.name }}</option>
          </b-select>
        </b-field>

        <b-field horizontal v-if="currentOutputMode.value === 2" label="Trigger partition">
          <b-select @input="modechange" required v-model="output.mode_data.trigger_partition_id" expanded
                    icon="circle-medium">
            <option v-for="_partition in partitions" :value="_partition.id">{{ _partition.name }}</option>
          </b-select>
        </b-field>

        <b-field horizontal v-if="currentOutputMode.value === 2" label="Duration (min)"
                 message="Amount of minutes the output will stay on after a triggered alarm. Set to 0 to keep lights on until disarm.">
          <b-numberinput @input="modechange"
                         required
                         v-model="output.mode_data.trigger_duration"
                         min="0"
                         exponential>
          </b-numberinput>
        </b-field>

        <b-field horizontal v-if="currentOutputMode.value === 3" label="Offset (min)"
                 message="Amount of minutes that the output will activate before sunset and deactivate after sunrise. E.g. '30' will activate the alarm 30mins before sunset, and '-30' 30mins after sunset.">
          <b-numberinput @input="modechange"
                         required
                         v-model="output.mode_data.nightmode_offset"
                         exponential>
          </b-numberinput>
        </b-field>

        <b-field horizontal label="Location" v-if="currentOutputMode.value === 3">
          <b-message type="is-info is-light">
            The current location of the Tower will be used to calculate the sunrise and sunset times for each day.
          </b-message>
        </b-field>

        <b-field v-if="currentOutputMode.value === 4" horizontal>
          <b-message type="is-danger is-light">
            This mode is not implemented yet. Stay tuned!
          </b-message>
        </b-field>

        <hr>

        <b-field horizontal>
          <b-field grouped position="is-right">
            <div class="control">
              <b-button :loading="isLoading" native-type="submit" type="is-primary">Update</b-button>
            </div>
          </b-field>
        </b-field>

      </form>
    </div>
  </card-component>
</template>

<script>
import alarm from "@/store/modules/alarm.store"
import CardComponent from "@/components/CardComponent";
import moment from 'moment'
import SchemeUpdateStatusComponent from "@/components/package/schemes/SchemeUpdateStatusComponent";

export default {
  name: "OutputFormComponent",
  components: {CardComponent},
  props: {
    partitions: {
      default: []
    },
    orig_output: {
      default: null
    },
    project_id: {
      default: null
    }
  },
  data() {
    return {
      output: {},
      isLoading: false,
      iconList: [
        {icon: 'car-light-high', name: 'Lights'},
        {icon: 'lightbulb-outline', name: 'Work Lights'},
        {icon: 'engine-outline', name: 'Generator'},
        {icon: 'alarm-light-outline', name: 'Siren'},
        {icon: 'lightning-bolt', name: 'Custom 1'},
        {icon: 'power-socket-eu', name: 'Custom 2'},
      ],
      modeList: [
        {icon: 'toggle-switch', value: 0, name: 'Manual', description: 'Items can be manually switched'},
        {
          icon: 'lock',
          value: 1,
          name: 'While Armed',
          description: 'items will be activated while selected partition is armed'
        },
        {
          icon: 'lock-alert',
          value: 2,
          name: 'When Triggered',
          description: 'Items will be activated when selected partition is triggered'
        },
        {
          icon: 'theme-light-dark',
          value: 3,
          name: 'Nightmode',
          description: 'Items will be activated from sunset to sunrise.'
        },
        {
          icon: 'lock-clock',
          value: 4,
          name: 'Follow Schema',
          description: 'Items will be (de)activated based on a schema'
        },

      ],
      currentOutputMode: {
        icon: 'toggle-switch',
        value: 0,
        name: 'Manual',
        description: 'Items can be manually switched'
      },

      changed: false,

    }
  },

  computed: {},
  created() {
    if (!alarm.isRegistered) {
      this.$store.registerModule('alarm', alarm)
      alarm.isRegistered = true
    }
  },

  mounted() {

    this.output = this.orig_output;
    if (this.orig_output.mode_data) {
      this.output.mode_data = JSON.parse(this.orig_output.mode_data);
    } else {
      this.output.mode_data = {};
    }
    this.currentOutputMode = this.modeList[this.orig_output.mode];


  },

  methods: {

    submit() {

      this.isLoading = true;

      if (this.changed) {
        // Update existing exception
        this.output.mode = this.currentOutputMode.value
      } else {
        delete this.output.mode;
        delete this.output.mode_data
      }
      this.$store.dispatch('alarm/updateOutput', {
        project_id: this.project_id,
        output_id: this.orig_output.id,
        data: this.output
      })
        .then(response => {
          this.$parent.close()
          this.$emit('refresh')
          this.$buefy.toast.open({
            message: 'Output updated',
            type: 'is-success'
          })
          this.isLoading = false

        }).catch(err => {
        this.$buefy.toast.open({
          message: err.response.data.message,
          type: 'is-danger'
        })
        this.isLoading = false

      })


    },

    // Set changed to true once a mode change is done
    modechange() {
      this.changed = true;
    },

    updateResultModal(part_upd) {
      this.$buefy.modal.open({
        parent: this,
        component: SchemeUpdateStatusComponent,
        props: {
          partition_updates: part_upd,
        },
        hasModalCard: true,
        trapFocus: true,
        events: {
          update: () => {
            this.$emit('refresh')
          }
        }
      })

    },
  }

}
</script>

<style scoped lang="scss">


</style>
