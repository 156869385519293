<template>
  <card-component title="Edit Sensor"
                  icon="pencil"
                  :is-scrollable="true">
    <div class="content">

      <form @submit.prevent="submit">

        <b-field horizontal label="Internal Name"
                 message="Name can only be changed through Risco CS and will be applied when synced." expanded>
          <b-input disabled v-model="sensor.internal_name"></b-input>
        </b-field>

        <b-field horizontal label="Name" message="Visible for clients" expanded>
          <b-input v-model="sensor.name"></b-input>
        </b-field>

        <b-field horizontal label="Type" message="Sensor type" expanded>
          <b-input v-model="sensor.type"></b-input>
        </b-field>

        <b-field horizontal label="In Use"
                 message="When in use, the output will be visible in Package details an can be switched. Make sure that output is set to 'Code' in risco CS.">
          <b-checkbox true-value="1" false-value="0" v-model="sensor.used">
          </b-checkbox>
        </b-field>

        <hr>

        <b-field horizontal>
          <b-field grouped position="is-right">
            <div class="control">
              <b-button :loading="isLoading" native-type="submit" type="is-primary">Update</b-button>
            </div>
          </b-field>
        </b-field>

      </form>
    </div>
  </card-component>
</template>

<script>
import alarm from "@/store/modules/alarm.store"
import CardComponent from "@/components/CardComponent";
import moment from 'moment'
import SchemeUpdateStatusComponent from "@/components/package/schemes/SchemeUpdateStatusComponent";

export default {
  name: "SensorFormComponent",
  components: {CardComponent},
  props: {
    partitions: {
      default: []
    },
    orig_sensor: {
      default: null
    },
    project_id: {
      default: null
    }
  },
  data() {
    return {
      sensor: {},
      isLoading: false,

    }
  },

  computed: {},
  created() {
    if (!alarm.isRegistered) {
      this.$store.registerModule('alarm', alarm)
      alarm.isRegistered = true
    }
  },

  mounted() {

    this.sensor = this.orig_sensor;


  },

  methods: {

    submit() {

      this.isLoading = true;

      this.$store.dispatch('alarm/updateSensor', {
        project_id: this.project_id,
        sensor_id: this.orig_sensor.id,
        data: this.sensor
      })
        .then(response => {
          this.$parent.close()
          this.$emit('refresh')
          this.$buefy.toast.open({
            message: 'Output updated',
            type: 'is-success'
          })
          this.isLoading = false

        }).catch(err => {
        this.$buefy.toast.open({
          message: err.response.data.message,
          type: 'is-danger'
        })
        this.isLoading = false

      })


    },

  }

}
</script>

<style scoped lang="scss">


</style>
